.toolbar {
    display: flex;
    flex-direction: row;
    height: 4.5rem;
    align-items: center;
    padding: 0.05rem $card-spacer-x 0;
    position: relative;

    &:not(.toolbar--inner) {
      background-color: $card-bg;
      border-radius: $border-radius;
      margin-bottom: $grid-gutter-width;
      box-shadow: $card-shadow;
    }
  
    .actions {
      margin: 0.05rem -0.8rem 0 auto;
    }
  }
  
  .toolbar--inner {
    margin-bottom: 1rem;
    border-radius: $border-radius $border-radius 0 0;
    background-color: rgba($black, 0.1);
  }
  
  .toolbar__nav {
    white-space: nowrap;
    overflow-x: auto;

    & > a {
      font-weight: $font-weight-bold;
      color: $text-muted;
      display: inline-block;
      transition: color 300ms;
  
      & + a {
        padding-left: 1rem;
      }
  
      &.active,
      &:hover {
        color: $body-color;
      }
    }
  }
  
  .toolbar__search {
    position: absolute;
    left: 0;
    top: 0;
    @include size(100%, 100%);
    border-radius: $border-radius;
    padding-left: 3rem;
    display: none;
    background-color: $bg-highlighted-darkest;
  
    input[type=text] {
      @include size(100%, 100%);
      border: 0;
      padding: 0 1.6rem;
      font-size: 1.2rem;
      background-color: transparent;
      color: $white;
      padding-bottom: 2px;
      
      @include placeholder() {
        color: $input-placeholder-color;
      }
    }
  }
  
  .toolbar__search__close {
    transition: color 300ms;
    cursor: pointer;
    position: absolute;
    top: 1.2rem;
    left: 1.8rem;
    font-size: 2rem;
    color: $white;
  
    &:hover {
      color: darkgrey;
    }
  }
  
  .toolbar__label {
    margin: 0;
    font-size: 1.1rem;
  }