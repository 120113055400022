.accordion {
  .card {
    box-shadow: none;
    margin: 0 0 1px;
  }

  .card-header {
    & > a {
      color: $headings-color;
      display: block;
      transition: color 300ms;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}