.photos {
  margin: 0 -4px;

  & > a {
    padding: 0;
    border: 4px solid transparent;

    img {
      border-radius: $border-radius;
      width: 100%;
    }
  }
}

.lightbox {
  & > a {
    position: relative;

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      left: 10px;
      top: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      background-color: rgba($white, 0.25);
      opacity: 0;
      transition: opacity 300ms;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
}