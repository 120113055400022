.results__header {
  padding: 2rem 2rem 0;
  border-radius: $border-radius $border-radius 0 0;
  margin-bottom: 2rem;
  background-color: rgba($black, 0.1);
}

.results__search {
  position: relative;

  input[type="text"] {
    width: 100%;
    border: 0;
    border-radius: $border-radius;
    background-color: $bg-highlighted-light;
    color: $input-color;
    padding: 0 1rem 0 3rem;
    height: 2.9rem;
    margin-bottom: 1rem;
    transition: background-color 300ms;
    
    @include placeholder {
      color: $input-placeholder-color;
    }

    &:focus {
      background-color: $bg-highlighted-dark;
    }
  }

  &:before {
    content: '';
    @include font-icon('\ea72', 1.3rem);
    position: absolute;
    top: 0.45rem;
    left: 1.1rem;
    z-index: 1;
  }
}

.results__nav {
  border: 0;
}