// Nunito Regular
@font-face {
  font-family:'Nunito';
  src:  url('../../../fonts/nunito/nunito-regular.woff2') format('woff2'),
        url('../../../fonts/nunito/nunito-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}


@font-face {
  font-family:'Nunito';
  src:  url('../../../fonts/nunito/nunito-semibold.woff2') format('woff2'),
        url('../../../fonts/nunito/nunito-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2212;
}


// Nunito Bold
@font-face {
  font-family:'Nunito';
  src:  url('../../../fonts/nunito/nunito-bold.woff2') format('woff2'),
        url('../../../fonts/nunito/nunito-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2212;
}