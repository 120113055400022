.inf__drop-area {
    * {
        box-sizing: border-box;
    }

    position: relative;
    display: flex;
    align-items: center;
    height: 65px;
    max-width: 100%;
    border: 1px dashed #c4c4c4;
    border-radius: 3px;
    padding: 0 20px;
    transition: 0.2s;
    margin-bottom: 8px;

    &:hover > {
        .inf__btn, .inf__drop-area {
            background-color: rgba(137, 196, 244, 0.4);
        }
    }

    &.is-active {
        background-color: rgba(137, 196, 244, 0.4);
    }
}

input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
   /* height: 100%;
    width: 100%;*/
    opacity: 0;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.inf__btn {
    display: inline-block;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    padding: 5px 12px;
    margin-right: 10px;
    font-size: 10px;
    text-transform: uppercase;
}

.inf__hint {
    flex: 1;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
