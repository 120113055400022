.input-group-prepend {
  margin-right: 0;

  .input-group-text {
    border-right-width: 0;
  }
}

.input-group-append {
  margin-left: 0;

  .input-group-text {
    border-left-width: 0;
  }
}

.input-group-text {
  [class*="zwicon-"] {
    font-size: 1.5rem;
  }
}