.alert-heading {
  font-weight: $font-weight-bold;
  font-size: 1.1rem;
  margin: 0.15rem 0 1rem;
  color: inherit;
}

.alert-info{
  h1, h2, h3, h4, h5{
    color: $white;
  }
}

.alert-link {
  border-bottom: 1px dotted rgba($white, 0.35);
}

.alert-error {
  color: #f8d7da;
  background-color: #c22f3d;
  border-color: #f5c6cb;
}

.alert-error hr {
  border-top-color: #f1b0b7;
}

.alert-error .alert-link {
  color: #f0acb2;
}