.team {
  margin-top: 7rem;
}

.team__item {
  text-align: center;
  margin-bottom: 7rem;

  @include media-breakpoint-down(sm) {
    max-width: 365px;
    margin: 0 auto 80px;
  }

  .card-subtitle {
    margin-bottom: 1rem;
  }
}

.team__img {
  display: inline-block;
  border-radius: 50%;
  @include size(150px, 150px);
  border: 5px solid $bg-highlighted-light;
  margin: -4rem auto -0.5rem;
}

.team__social {
  margin-top: 2rem;

  & > a {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 32px;
    border-radius: 50%;
    color: $white;
    background-color: $bg-highlighted-light;
    font-size: 1.5rem;
    margin: 0 1px;
    transition: background-color 300ms;

    &:hover {
      background-color: rgba($white, 0.2);
    }
  }
}