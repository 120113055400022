.contacts {
  @include media-breakpoint-down(xs) {
    margin: 0 -5px;

    & > [class*="col-"] {
      padding: 0 5px;
    }
  }
}

.contacts__item {
  @include card();
  padding: 1.8rem 1.5rem 1.15rem;
  text-align: center;
  margin-bottom: $grid-gutter-width;

  &:hover {
    .contacts__img > {
      img, i {
        transform: scale(1.1);
      }
    }
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 10px;
  } 
}

.contacts__img {
  display: block;
  margin-bottom: 1.1rem;

  & > img {
    max-width: 120px;
    max-height: 120px;
    width: 100%;
    border-radius: 50%;
    transition: transform 300ms;
  }
}

.contacts__info {
  strong,
  small {
    @include text-truncate();
    display: block;
  }

  strong {
    font-weight: $font-weight-bold;
  }

  small {
    font-size: 0.9rem;
    color: $text-muted;
  }
}

.contacts__btn {
  margin-top: 10px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  border: 0;
  line-height: 100%;
  background-color: transparent;
  color: $white;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: $border-radius;
  transition: background-color 300ms, color 300ms;
  font-size: 0.9rem;

  &:hover {
    background-color: $bg-highlighted-light;
  }
}

.new-contact__header {
  background-color: rgba($black, 0.1);
  text-align: center;
  padding: 30px 0;
  border-radius: $card-border-radius $card-border-radius 0 0;
}

.new-contact__img {
  border-radius: 50%;
  box-shadow: 0 0 0 5px $bg-highlighted-light;
  @include size(150px, 150px);
  @include media-breakpoint-down(sm) {
    @include size(100px, 100px);
  }
}

.new-contact__upload {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 1.5rem;
  @include size(40px, 40px);
  border-radius: 50%;
  line-height: 38px;
  background-color: $bg-highlighted-light;
  color: $white;
  transition: background-color 300ms;

  &:hover {
    color: $white;
    background-color: rgba($white, 0.2);
  }
}