.select2-container--default {
  .select2-selection--single {
    border-radius: 0;
    background: $bg-highlighted-light;
    border-color: rgba($black, 0);
    height: auto;

    .select2-selection__arrow {
      display: none;
    }

    .select2-selection__placeholder {
      color: rgba($white, 0.5);
    }

    .select2-selection__rendered {
      line-height: $input-btn-line-height;
      padding: $input-btn-padding-y $input-btn-padding-x;
      color: $white
    }
  }

  #select2-letter_of_intent_gfa_customer-container{
    border: 1px solid rgb(225, 225, 225);
    color: black;
  }

  #select2-letter_of_intent_gfa_departmentReferential-container{
    border: 1px solid $black;
    color: black;
  }

  #select2-underwriter_mrp_customer-container{
    border: 1px solid rgb(225, 225, 225);
    color: black;
  }

  #select2-underwriter_pne_customer-container{
    border: 1px solid rgb(225, 225, 225);
    color: black;
  }

  #select2-underwriter_rcde_customer-container{
    border: 1px solid rgb(225, 225, 225);
    color: black;
  }

  #select2-underwriter_rcpro_customer-container{
    border: 1px solid rgb(225, 225, 225);
    color: black;
  }

  #select2-underwriter_rcd_customer-container{
    border: 1px solid rgb(225, 225, 225);
    color: black;
  }

  #select2-underwriter_dommage_customer-container{
    border: 1px solid rgb(225, 225, 225);
    color: black;
  }

  &.select2-container--focus {
    .select2-selection--multiple {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }

  .select2-selection--multiple {
    background-color: transparent;
    border-color: $input-border-color;
    border-radius: 0;
    transition: border-color 300ms;

    .select2-selection__choice {
      border-radius: 0;
      border: 0;
      background-color: $bg-highlighted-light;
      padding: 0.2rem 0.75rem;
      color: $input-color;
    }

    .select2-selection__choice__remove {
      margin-right: 0.5rem;
      color: $input-color;

      &:hover {
        color: $input-color;
        opacity: 0.75;
      }
    }
  }

  &.select2-container--disabled {
    .select2-selection--single {
      background-color: transparent;
      opacity: 0.5;
    }
  }

  .select2-results__option[aria-selected=true] {
    &, &:hover, &:focus {
      background-color: rgba($black, 0.25);
      color: $black;
    }
  }
}

.select2-selection--single,
.select2-selection--multiple {
  transition: border-color 300ms, box-shadow 300ms;
}

.select2-container--open {
  .select2-selection--single {
    box-shadow: $input-focus-box-shadow;
    border-color: none;
  }
}

.select2-dropdown {
  background-color: $white;
  border: 0;
  border-radius: $border-radius;
  padding: $dropdown-padding-y 0;
  box-shadow: $dropdown-box-shadow;
  z-index: $header-z-index - 1;
  @include animate(fadeIn, 300ms);

  .select2-results__option {
    padding: 0.65rem $dropdown-item-padding-x;
  }

  .select2-results__option--highlighted[aria-selected] {
    &:not([aria-selected=true]) {
      background-color: $dropdown-link-hover-bg;
      color: $dropdown-link-hover-color;
    }
  }

  .select2-results__option[aria-disabled=true] {
    color: $input-color;
    opacity: 0.25;
  }

  .select2-search--dropdown {
    margin-top: -0.5rem;
    border-bottom: 1px solid rgba($white, 0.08);
    position: relative;
    margin-bottom: 1rem;

    &:before {
      @include font-icon('\ea72', 1.5rem);
      color: $body-color;
      position: absolute;
      left: 1.4rem;
      top: 0.5rem;

    }

    .select2-search__field {
      border: 0;
      background-color: $input-bg;
      height: 2.8rem;
      padding: 0 0 0.2rem 3.5rem;
    }
  }
}

.select2-search__field {
  color: $input-color;
  
  @include placeholder() {
    color: $input-placeholder-color;
  }
}

.select2-container {
  .select2-search--inline {
    .select2-search__field {
      margin: 8px 0 2px;
      padding: 0 5px;
    }
  }
}

#select2-custom_do_customer-container{
  border: 1px solid rgb(225, 225, 225);
  color: black;
}
