.form-control {
  resize: none;
  appearance: none;
  -ms-overflow-style: none;
  border: 1px solid black;
  color: $black;
}
.form-control:focus {
  border: 1px solid black;
}

.form-control[readonly]{
  color: black;
}

.form-group {
  position: relative;
  color: black;
}

select::-ms-expand {
  display: none;
}

select.form-control {
  background: $select-bg;
}

.login .form-control{
  color: white;
  &::placeholder {
    color: rgba($white, 0.5);
  }
} 

.new-contact .form-control{
  color: white;
  &::placeholder {
    color: rgba($white, 0.5);
  }
} 

.new-contact .help-text{
  color: white;
}

.login .text-primary{
  color: #a4d0ff !important;
}

.login .text-primary:hover{
  color: $white !important;
}

.login button:hover{
  color: $white !important;
}

// validation
.valid-tooltip,
.invalid-tooltip {
  margin-top: 0;
  border-radius: 0;
  padding: 0.25rem 0.5rem 0.35rem;
}