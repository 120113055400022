.quick-stats__item {
  padding: 1.85rem 1.75rem 1.75rem 2rem;
  @include clearfix();
  border-radius: $border-radius;
  box-shadow: $card-shadow;
  margin-bottom: $grid-gutter-width;
  display: flex;
  align-items: center;
  background-color: $white;
  border: 1px solid rgb(225, 225, 225);

  & > .peity {
    margin-left: auto;
    padding-left: 1.2rem;

    @include media-breakpoint-between(sm, lg) {
      display: none;
    }
  }
}

.quick-stats__info {
  min-width: 0;

  & > h2,
  & > small {
    line-height: 100%;
    @include text-truncate();
  }

  & > h2 {
    margin: 0;
    font-size: 1.3rem;
    color: $black;
  }

  & > small {
    font-size: 1rem;
    display: block;
    color: $black;
    margin-top: 0.85rem;
  }
}

.stats {
  padding-top: 1rem;
}

.stats__item {
  @include card();
  margin-bottom: $grid-gutter-width;
  padding: 1rem;
}

.stats__chart {
  border-radius: $border-radius;
  padding-top: 1rem;
  overflow: hidden;

  .flot-chart {
    margin: 0 -12px -12px;
  }
}

.stats__info {
  padding: 1.8rem 1rem 0.5rem;
  position: relative;
  text-align: center;

  h2 {
    font-size: 1.3rem;
    margin: 0;

  }

  small {
    display: block;
    font-size: 1rem;
    margin-top: 0.4rem;
    color: $text-muted;
  }
}