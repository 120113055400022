.flot-chart {
  height: 200px;
  display: block;
}

.flot-chart-legends {
  text-align: center;
  margin: 20px 0 -10px;
}

.flot-chart-legends table {
  display: inline-block;
}

.flot-chart-legends .legendColorBox > div > div {
  border-radius: 50%;
}

.flot-chart-legends .legendLabel {
  padding: 0 8px 0 3px;
  color: rgba(255,255,255,.5);
}