.widget-profile {
}

.widget-profile__img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1.2rem;
  border: 5px solid rgba($white, 0.1);
}

.widget-profile__list {
  color: $white;

  .media {
    padding: 1rem 2rem;

    &:nth-child(even) {
      background-color: $bg-highlighted-lightest;
    }
  }

  .media-body {
    strong{
      display: block;
      font-weight: $font-weight-bold;
      color: black;
    }

    small {
      color: $text-muted;
      font-size: 0.92rem;
      color: grey;
    }
  }
}