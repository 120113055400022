.dropdown-item {
  transition: background-color 300ms, color 300ms;
}

.dropdown-menu {
  @include animate(fadeIn, 300ms);
  box-shadow: $dropdown-box-shadow;
}

.dropdown-header {
  font-size: 1rem;
  border-bottom: 1px solid $bg-highlighted-light;

  .actions {
    position: absolute;
    top: 0.4rem;
    right: 0.8rem;
  }
}

.dropdown-menu--block {
  width: 320px;

  .dropdown-header {
    padding-top: 1.1rem;
    padding-bottom: 1rem;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.dropdown-menu--icon {
  .dropdown-item {
    & > i {
      line-height: 100%;
      vertical-align: top;
      font-size: 1.4rem;
      margin-right: 0.75rem;
    }
  }
}

.dropdown-menu--invert {
  background-color: $dropdown-menu-invert-bg;

  .dropdown-item {
    color: $dropdown-item-invert-color;

    &:hover {
      background-color: $dropdown-item-invert-hover-bg;
    }
  }
}