.dropzone {
  border: 0;
  background-color: $bg-highlighted-dark;
  border-radius: $border-radius;
  transition: background-color 300ms;
  min-height: 50px;
  position: relative;


  &:before {
    @include font-icon('\ea7c', 2rem);
    font-size: 2rem;
    color: $body-color;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: $bg-highlighted-light;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 300ms;
  }

  .dz-preview {
    &.dz-file-preview,
    &.dz-image-preview {
      .dz-image {
        border-radius: $border-radius;
      }
    }

    .dz-remove {
      position: absolute;
      top: -9px;
      right: -9px;
      z-index: $header-z-index - 1;
      font-size: 0;
      width: 22px;
      height: 22px;
      background-color: $red;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $red;
        text-decoration: none;
      }

      &:before {
        @include font-icon('\ea4c', 1.25rem);
        color: $white;
      }
    }
  }

  .dz-message {
    transition: opacity 300ms;

    span {
      display: inline-block;
      border-radius: $border-radius-lg;
      transition: color 300ms, box-shadow, 300ms;
      padding: 0.5rem 1.4rem 0.8rem;
      background-color: $bg-highlighted-light;

      &:before {
        @include font-icon('\ea7c', 1.75rem);
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 0.8rem;
      }
    }
  }

  &:hover {
    .dz-message {
      span {
        color: $body-color;
      }
    }
  }

  &:hover,
  &.dz-drag-hover {
    background-color: rgba($black, 0.25);
  }

  &.dz-drag-hover {
    .dz-message {
      opacity: 0;
    }

    &:before {
      opacity: 1;
    }
  }
}

