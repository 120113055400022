//Listing
.notes__item {
  margin-bottom: $grid-gutter-width;

  & > a {
    height: 155px;
    background-color: $card-bg;
    display: block;
    padding: 1.8rem 2rem;
    position: relative;
    color: rgba($white, 0.75);
    transition: background-color 300ms;

    &,
    &:before {
      border-radius: $border-radius;
      box-shadow: 0 1px 1px rgba($black,0.08);
    }

    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 10px);
      bottom: -5px;
      left: 5px;
      z-index: -1;
      height: 5px;
      background-color: rgba($black, 0.4);
    }
  }

  &:hover {
    .notes__actions {
      opacity: 1;
    }
  }
}

.notes__title {
  color: $headings-color;
  margin-bottom: 1.1rem;
  font-weight: $font-weight-bold;
  font-size: 1.1rem;
  @include text-truncate();
}

.notes__actions {
  position: absolute;
  right: 2.2rem;
  bottom: 1rem;
  font-size: 1.1rem;
  width: 2.2rem;
  height: 2.2rem;
  text-align: center;
  background: $bg-highlighted-darkest;
  border-radius: 50%;
  line-height: 2rem;
  color: $white;
  box-shadow: 0 0 4px rgba($black,0.5);
  opacity: 0;
  transition: opacity 300ms;
  cursor: pointer;

  &:hover {
    background: rgba($black,0.9);
  }
}


//New Note Modal
.note-view {
  .trumbowyg-editor {
    border: 1px solid $input-border-color;
  }
}