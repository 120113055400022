.q-a {

}

.q-a__item {
  align-items: flex-start;
}

.q-a__stat {
  margin: 0.35rem 2rem 0 0;
  align-self: flex-start;

  & > span {
    display: inline-block;
    width: 70px;
    border-radius: $border-radius;
    background-color: $bg-highlighted-light;
    text-align: center;
    padding: 0.9rem 0.5rem 0.65rem;
    margin-right: 0.2rem;

    & > strong {
      display: block;
      font-size: 1.2rem;
      font-weight: $font-weight-bold;
      line-height: 100%;
      margin-bottom: 0.25rem;
    }

    & > small {
      text-transform: uppercase;
      line-height: 100%;
    }
  }
}

.q-a__question {
  position: relative;
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    margin-top: 1rem;
  }

  @include media-breakpoint-up(sm) {
    padding-left: 100px;
  }

  & > h2 {
    font-size: 1.3rem;

    & + p {
      margin-top: 1rem;
    }
  }

  & > p {
    font-size: 1.1rem;
  }

  .q-a__info {
    border-top: 1px solid $border-light;
  }
}

.q-a__vote {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;

  [class*="zwicon-"] {
    font-size: 3rem;
  }
}

.q-a__vote__votes {
  padding: 0.5rem 0 0.6rem;
  background-color: $bg-highlighted-light;
  border-radius: $border-radius;
  font-weight: $font-weight-bold;
  width: 75px;
  font-size: 1.5rem;
  margin-bottom: -0.2rem;
}

.q-a__info {
  margin-top: 1.5rem;
  padding: 1.25rem 0;
  position: relative;

  .actions {
    position: absolute;
    top: 1rem;
    right: 0;
  }
}

.q-a__op {
  display: flex;
  align-items: center;

  & > a {
    & > img {
      width: 30px;
      height: 30px;
      border-radius: $border-radius;
      vertical-align: middle;
      margin-right: 1rem;
    }
  }
}

.q-a__answers {
  margin-top: 3rem;
}