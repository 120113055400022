.__checkbox {
    padding: 20px !important;
}

.__has-file {
    opacity: 0.8 !important;
    background-color: #c9c9c9;
}

.__has-bank-loan-chosen {
    display: none;
}

.custom-control-input:not(:checked) ~ .custom-control-label::before {
    background-color: $white; /* Change the background color of the toggle when it is not checked */
    border-color: grey; /* Change the border color of the checkbox when it is not checked */
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: grey; /* Change the color of the toggle when it is checked */
}

.upload-file {
    width: 50px;
    height: 50px;
}
