.flatpickr-calendar {
  border-radius: $border-radius;
  border: 0;
  box-shadow: $dropdown-box-shadow;
  width: auto;
  user-select: none;
  background-color: $bg-highlighted-darkest;
  margin-top: -3px;

  &:before,
  &:after {
    display: none;
  }
}

.flatpickr-months {
  border-radius: $border-radius $border-radius 0 0;

  .flatpickr-month {
    height: 60px;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 30px;
    border-radius: $border-radius;
    color: $white;
    top: 13px;
    font-size: 2rem;
    transition: background-color 300ms;

    &:hover {
      color: $white;
      background-color: rgba($white, 0.1);
    }
  }

  .flatpickr-prev-month {
    margin-left: 15px;
  }

  .flatpickr-next-month {
    margin-right: 15px;
  }
}

.flatpickr-current-month {
  font-size: 1.25rem;
  text-transform: uppercase;
  color: $white;
  padding-top: 18px;

  span.cur-month,
  input.cur-year {
    font-weight: $font-weight-bold;
  }

  span.cur-month:hover {
    background-color: transparent;
  }

  .numInputWrapper {
    &:hover {
      background-color: transparent;
    }

    span {
      border: 0;
      right: -5px;
      padding: 0;

      &:after {
        left: 3px;
      }

      &.arrowUp:after {
        border-bottom-color: $white;
      }

      &.arrowDown:after {
        border-top-color: $white;
      }
    }
  }
}

span.flatpickr-weekday {
  font-weight: $font-weight-base;
  color: $headings-color;
}

.flatpickr-day {
  font-size: 0.92rem;
  border: 0;
  color: $flatpickr-color;

  &.selected {
    &, &:hover {
      background-color: $white !important;
      color: $color-inverse !important;
    }
  }

  &.today {
    &, &:hover {
      background-color: rgba($white, 0.1);
    }
  }

  &:hover {
    background-color: rgba($white, 0.1);
  }

  &.nextMonthDay,
  &.prevMonthDay {
    color: rgba($white, 0.25);

    &:hover {
      background-color: rgba($white, 0.05);
    }
  }
}

.numInputWrapper {
  span {
    &:hover {
      background-color: rgba($white, 0.1);
    }
  }
}

.flatpickr-time {
  border-top: 1px solid rgba($white, 0.1) !important;

  .numInputWrapper,
  .flatpickr-am-pm {
    &:hover {
      background-color: rgba($white, 0.005);
    }
  }
  
  input,
  .flatpickr-am-pm {
    color: $flatpickr-color;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.flatpickr-innerContainer {
  padding: 5px 0;
}