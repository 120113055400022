.card {
  margin-bottom: $card-columns-margin;
  box-shadow: $card-shadow;
  background-color: $white;

  &:not([class*="border-"]) {
    border: 1px solid rgb(225, 225, 225);
  }
}

.card-title {
  font-size: 1.25rem;
  line-height: 140%;
  margin-top: -5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.customer_update_fields{
  border: none;
}

#customer_update_fields .card{
  background-color: $white;
  border: none;
}

#customer_update_fields label{
  color: $black
}

#customer_update_fields_numberEmployees{
  color: black;
  background-color: $white;
  border: 1px solid rgb(225, 225, 225);
}

#_customer-number-employees .modal-dialog .modal-content{
  background-color: $white;
  color: black;
}

#_customer-number-employees .modal-dialog .modal-content h4{
  background-color: $white;
  color: black;
}

.modal-content{
  background-color: $white;
  color: $black
}

.modal-content .modal-header i{
  color: grey !important;
}

.customer_form{
  border: none;
}

.card-subtitle {
  color: $black;
  font-size: 1rem;
  font-weight: normal;
  margin-top: -1.55rem;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.card > .actions,
.card-body .actions {
  position: absolute;
  right: 15px;
  z-index: 2;
  top: 18px;
}

[class*="card-img"] {
  width: 100%;
}

.card-link {
  text-transform: uppercase;
  font-size: 0.98rem;
  color: $text-muted;
  font-weight: $font-weight-bold;
  display: inline-block;
  margin-top: 1rem;
  transition: color 300ms;

  &:hover {
    color: $white;
  }
}

.card-body {
  .card-body {
    margin-bottom: 0;
  }
}


.card-body__title {
  font-size: 1.15rem;
  color: $headings-color;
  margin-bottom: 1rem;
  font-weight: normal;
}

.card--fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $header-z-index + 1;
  background-color: $bg-highlighted-darkest;
  overflow: auto;
}

.card-header {
  padding: 1.2rem $card-spacer-x;
}

.card-group {
  box-shadow: $card-shadow;

  .card {
    box-shadow: none;
  }
}
