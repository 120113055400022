.breadcrumb {
  border-bottom: 1px solid $bg-highlighted-light;
  border-radius: 0;
}

.breadcrumb-item {
  &:not(.active) {
    & > a {
      color: $text-muted;

      &:hover {
        color: $white;
      }
    }
  }
}