.carousel-item {
  img {
    width: 100%;
    border-radius: $border-radius;
  }
}

.carousel-caption {
  background-color: rgba($black, 0.5);
  border-radius: $border-radius $border-radius 0 0;
  bottom: 0;
  font-weight: $font-weight-bold;
  padding-bottom: 35px;

  h3 {
    color: $white;
    font-size: 1.3rem;
  }
}

.carousel-control-prev,
.carousel-control-next {
  &:hover {
    background-color: rgba($black, 0.25);
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: auto;
}