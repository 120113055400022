
#select2-legal_contract_customer-container{
  border: 1px solid rgb(225, 225, 225);
  color: black;
}
.jurifisc {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.jurifisc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.jurifisc:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.jurifisc input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.jurifisc input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.jurifisc .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* The jurifiscradio */
.jurifiscradio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.jurifiscradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkradio {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.jurifiscradio:hover input ~ .checkmarkradio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.jurifiscradio input:checked ~ .checkmarkradio {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkradio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.jurifiscradio input:checked ~ .checkmarkradio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.jurifiscradio .checkmarkradio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.__card-product-fisca {
  width: auto;
  /*box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);*/
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  //overflow: hidden;
  position: relative;
  margin-left: 2%;

  img {
    position: relative;
    width: 100%;
    height: 250px;
    transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  }

  .__card-title {
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    //height: 50px;
    position: absolute;
    bottom: 8px;

    h3 {
      line-height: 48px;
      margin: auto;
      font-family: 'Nunito',sans-serif;
      text-align: center;
      vertical-align: middle !important;
    }
  }
}


.__descriptions-product-fisca {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  transition: all .7s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  clip-path: circle(0% at 100% 100%);

  ul li {
    color: black;
    text-decoration: none;
  }
}

.__card-product-fisca {
  &:hover {
    .__descriptions-product-fisca {
      left: 0;
      transition: all .7s ease-in-out;
      clip-path: circle(75%);
    }

    transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    transform: scale(0.97);
  }

  h1 {
    color: #ff3838;
    letter-spacing: 1px;
    margin: 0;
  }

  p {
    line-height: 24px;
    height: 70%;
  }

  ul  {
    list-style: none;
    text-transform: none;
    text-align: left;
  }
  ul li {
    color: black;
    text-decoration: none;
    font-size: 11px;
  }
}

