// View More Link
.view-more {
  display: block;
  padding: 1.1rem 0;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  transition: color 300ms;
  color: $text-muted;

  &:hover {
    color: $white;
  }
}

.load-more {
  text-align: center;
  margin-top: 2rem;

  & > a {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border: 2px solid rgba($black, 0.15);
    border-radius: $border-radius;
    color: $body-color;
    transition: border-color 300ms;

    & > i {
      font-size: 1.5rem;
      transition: transform 300ms;
      margin-right: 0.5rem;
    }

    &:hover {
      border-color: rgba($black, 0.35);

      & > i {
        transform: rotate(360deg);
      }
    }
  }
}

.card-body {
  .view-more {
    padding: 1rem 0 0;
  }
}

// Action Icons
.actions {
  display: flex;
  align-items: center;
}

.actions__item {
  font-size: 1.5rem;
  cursor: pointer;
  color: $body-color;
  transition: background-color 300ms, color 300ms;
  @include size(35px, 35px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.show,
  &:hover {
    background-color: $bg-highlighted-dark;
    color: $white;
  }
}

.actions__item--active {
  background-color: $bg-highlighted-light;
}


// Icon Lists
.icon-list {
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    position: relative;
    padding: 0.3rem 0;

    address {
      display: inline-block;
      vertical-align: top;
      margin-top: 0.25rem;
    }

    & > i {
      width: 2.5rem;
      text-align: center;
      font-size: 1.25rem;
      top: 0.12rem;
      position: relative;
      font-style: normal;
      display: inline-block;
    }
  }
}


// Avatars
.avatar-img,
.avatar-char {
  border-radius: $border-radius;
  @include size(3rem, 3rem);
  margin-right: 1.2rem;
}

.avatar-char {
  line-height: 2.9rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  color: $white;
  background-color: $bg-highlighted-light;
  font-style: normal;

  & > i {
    line-height: 2.8rem;
    font-size: 1.3rem;
  }
}


// Backdrops
.sa-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%, 100%);
  cursor: pointer;
  z-index: $sidebar-z-index - 1;
}


// Tags
.tags {
  & > a {
    display: inline-block;
    padding: 0.65rem 1rem;
    border: 2px solid $bg-highlighted-light;
    color: $body-color;
    margin: 0 0.3rem 0.5rem 0;
    transition: border-color 300ms;

    &:hover {
      border-color: rgba($white, 0.2);
    }
  }
}


// Dashboard
.widget-lists {
  column-gap: $grid-gutter-width;

  @media(min-width: 1500px) {
    column-count: 3;
  }

  @media (min-width: 900px) and (max-width: 1499px) {
    column-count: 2;
  }

  @media screen and (max-width: 899px) {
    column-count: 1;
  }
}