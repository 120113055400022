//node_modules
@import "libs/zwicon/zwicon";
@import "~jquery-ui/themes/base/all.css";
@import "~animate.css/animate.css";
@import "~overlayscrollbars/css/OverlayScrollbars.css";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~flatpickr/dist/flatpickr.css";
@import "~bootstrap-colorpicker/src/sass/colorpicker";
@import "~trumbowyg/dist/ui/trumbowyg.css";
@import "~jqtree/jqtree";
@import "~select2/dist/css/select2.min.css";

// Font
@import "inc/templates/fonts";

// Variables
@import "inc/templates/variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Mixins
@import "inc/templates/mixins"; // This also includes Bootstrap Mixins

// Include customs
@import "inc";