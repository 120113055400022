.page-item {
  &.disabled {
    opacity: 0.6;
  }
}

.page-item.active .page-link{
  background-color: $bg-highlighted-dark;
}

.page-link {
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  margin: 0 2px;

  & > i {
    font-size: 1.5rem;
    line-height: 32px;
  }
}