.__card-product {
    width: auto;
    background: #fff;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    //overflow: hidden;
    position: relative;

    img {
        position: relative;
        width: 100%;
        height: 250px;
        transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    }

    .__card-title {
        background-color: rgba(255, 255, 255, 0.6);
        width: 100%;
        //height: 50px;
        position: absolute;
        bottom: 8px;

        h3 {
            line-height: 48px;
            margin: auto;
            font-family: 'Nunito',sans-serif;
            text-align: center;
            vertical-align: middle !important;
        }
    }
}

.__card-img-transparency {
    opacity: .2;
    cursor: auto;
}

.__descriptions-product {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    transition: all .7s ease-in-out;
    padding: 20px;
    box-sizing: border-box;
    clip-path: circle(0% at 100% 100%);

    ul li {
        color: black;
        text-decoration: none;
    }
}

.__card-product {
    &:hover {
        .__descriptions-product {
            left: 0;
            transition: all .7s ease-in-out;
            clip-path: circle(75%);
        }

        transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
        transform: scale(0.97);
    }

    h1 {
        color: #ff3838;
        letter-spacing: 1px;
        margin: 0;
    }

    p {
        line-height: 24px;
        height: 70%;
    }
}