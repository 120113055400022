@mixin sidebar-toggled() {
  background-color: $sidebar-hidden-bg;
  transition: transform 300ms, opacity 300ms;

  &:not(.toggled) {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  &.toggled {
    box-shadow: 5px 0 10px rgba($black, 0.25);
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@mixin user-inverse() {
  .user__info {
    background-color: rgba($white, 0.09);

    &:hover {
      background-color: rgba($white, 0.1);
    }
  }
}

.aside-toggled {
  overflow: hidden;
}

.sidebar {
  width: $sidebar-width;
  position: fixed;
  left: 0;
  height: 100%;
  overflow: hidden;
  z-index: $sidebar-z-index;
  background-color: $sidebar-bg;
  border-right: 1px solid rgb(182, 178, 178);

  @include media-breakpoint-up(xl) {
    &:not(.sidebar--hidden) {
      top: $header-height;
      height: calc(100% - #{$header-height});
    }
  }

  @include media-breakpoint-down(lg) {
    @include sidebar-toggled();
    @include user-inverse();
  }

  .os-content {
    padding: 15px !important;
  }
}

.sidebar--hidden {
  @include sidebar-toggled();
  top: 0;
  height: 100%;
  overflow: hidden;
  @include user-inverse();
}

.sidebar__inner {
  padding: $grid-gutter-width 2rem 0.5rem 2rem;
}


// User
.user {
  border-radius: $border-radius;
  position: relative;
  margin-bottom: 15px;

  .dropdown-menu {
    width: 100%;
    border-radius: 0 0 $border-radius $border-radius;
    border: 1px solid rgba($white, 0.1);
    border-top: 0;
    
    @include media-breakpoint-down(lg) {
      
    }
  }
}

.user__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 1rem;
  border-radius: $border-radius;
  background-color: $navigation-link-active-bg;
  transition: background-color 300ms;

  &:hover {
    background-color: rgba($white, 0.1);
  }
}

.user__img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}

.user__name {
  font-weight: $font-weight-bold;
  margin-top: 0.2rem;
}

.user__email {
  color: $text-muted;
}

.user__info__sidebar .user__name{
  color: black;
}

.user__info__sidebar .user__email{
  color: black;
}

// Site Navigation
.navigation {
  list-style: none;
  padding: 0;

  li {
    a {
      transition: background-color 300ms, color 300ms;
      font-weight: $font-weight-bold;
      display: block;
    }

    &:not(.navigation__active):not(.navigation__sub--active) {
      a {
        color: $black;

        &:hover {
          background-color: $bg-highlighted-dark;
          color: $black;
        }
      }
    }
  }

  & > li {
    & > a {
      padding: 0.75rem 1rem 0.85rem;
      position: relative;
      border-radius: $border-radius;

      & > i {
        vertical-align: top;
        font-size: 1.75rem;
        position: relative;
        top: 0.1rem;
        width: 1.5rem;
        text-align: center;
        margin-right: 0.6rem;
      }
    }
  }

  & > .navigation__active,
  & > .navigation__sub--active {
    margin: 2px 0;

    & > a {
      background-color: $navigation-link-active-bg;
      color: $navigation-link-active-color;
    }
  }
}

.navigation__sub {
  & > a {
    margin-bottom: 1px;
  }

  & > ul {
    border-radius: $border-radius;
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0;

    & > li {
      & > a {
        padding: .6rem 1rem .6rem 2.4rem;
      }

      &:last-child {
        padding-bottom: 0.8rem;
      }
    }
  }

  &:not(.navigation__sub--active) {
    & > ul {
      display: none;
    }
  }

  .navigation__active {
    position: relative;

    &:before {
      @include size(5px, 5px);
      content: '';
      border-radius: 50%;
      background-color: $white;
      position: absolute;
      left: 1.5rem;
      top: 1.1rem;
    }
    
    & > a {
      color: $navigation-link-active-color;
    }
  }
}