.price-table {
  text-align: center;

  &:not(.price-table--highlight) {
    margin: 0 -10px;

    & > [class*="col-"] {
      padding: 0 10px;
      text-align: center;
    }
  }
}

.price-table--highlight {
  margin: 0;

  & > [class*="col-"] {
    padding: 0;
  }
}

.price-table__item {
  margin-bottom: 20px;
  @include card();

  @include media-breakpoint-down(sm) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.price-table__item--popular {
  @include media-breakpoint-up(md) {
    padding-bottom: 1rem;
    position: relative;
    z-index: 1;
    margin: -1rem 0 0;
    box-shadow: 0 -5px 30px rgba($black, 0.4);

    .price-table__header {
      padding: 2.5rem 2rem 2.5rem;
      background-color: rgba($black, 0.35);
    }
  }
}

.price-table__header {
  color: $white;
  border-radius: $border-radius $border-radius 0 0;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: $bg-highlighted-dark;
}

.price-table__title {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: 1.3rem;
}

.price-table__desc {
  color: rgba($white, 0.75);
  margin: 0.3rem 0;
}

.price-table__price {
  font-size: 1.8rem;

  & > small {
    font-size: 1rem;
    position: relative;
    top: -0.4rem;
  }
}

.price-table__info {
  @include list-unstyled;
  padding: 1rem 0;

  & > li {
    font-weight: $font-weight-bold;
    padding: 1rem 1.5rem;

    & + li {
      border-top: 1px solid $border-lightest;
    }
  }
}

.price-table__action {
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  padding: 0.8rem 1.2rem;
  border-radius: $border-radius;
  color: $white;
  background-color: $bg-highlighted-dark;
  font-weight: $font-weight-bold;
  box-shadow: 0 3px 5px rgba($black, 0.12);
  transition: opacity 300ms;
  
  &:hover,
  &:focus {
    opacity: 0.9;
    color: $white;
  }
}