.widget-time {
  padding: 2rem;

  .time {
    font-size: 2rem;
    text-align: center;

    & > span {
      padding: 1rem 1.5rem;
      background-color: $bg-highlighted-light;
      border-radius: $border-radius;
      display: inline-block;
      line-height: 50px;
      margin: 0 5px;
      position: relative;

      &:after {
        position: absolute;
        right: -13px;
        top: 10px;
      }
    }
  }
}