.todo__item {
  display: flex;
  align-items: flex-start;

  & > label {
    margin-right: 1.25rem;
  }

  & > input[type=checkbox] {
    &:checked {
      & ~ .listview__content {
        &, .listview__attrs > span {
          text-decoration: line-through;
        }
      }
    }
  }
}