.table {
  th {
    font-weight: $font-weight-bold;
  }

  th, td {
    padding: 1rem 0.8rem;
    vertical-align: middle;
  }

  thead {
    & > tr {
      & > th {
        border-bottom: 0;
        vertical-align: middle;
      }
    }
  }
}

.thead-dark,
.thead-light {
  & > tr {
    & > th {
      border-top: 0;
    }
  }

  & + tbody {
    & > tr:first-child {
      & > td,
      & >th {
        border-top: 0;
      }
    }
  }
}