.scroll-custom::-webkit-scrollbar-track {
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    background: 0 0;
    zoom: 1;
    background: rgba(0,0,0,.1);
}

.scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    background: 0 0;
    zoom: 1;
    background: rgba(0,0,0,.2);
}

.scroll-custom::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.nav-tabs .nav-link {
    color: grey;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: black;
    background-color: white;
    border-color: #dee2e6 #dee2e6 transparent;
    border-radius: 3px 3px 0 0;
}

select option {
    color: black;
}

.content {
    min-height: calc(100vh - 90px);
}

.footer {
    height: 50px;
}

.__image_error_404 {
    height: auto;
    width: 100%;
    background: url("https://images.pexels.com/photos/262272/pexels-photo-262272.jpeg") no-repeat center;
    background-size: cover;
}

.__no-background {
    background-color: transparent;
}

.__custom-box-body {
    background: transparent;
}

.__btn-file {
    position: relative;
    overflow: hidden;
    margin-left: 5px;

    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity = 0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
    }
}

.__delete_file {
    float: inherit;
    opacity: .5;
    margin-top: -15px;
}

.box-pack {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);

    &:hover {
        z-index: 2;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1.05);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1.05);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1.05);
        transition: all 200ms ease-in;
        transform: scale(1.05);
    }

    &.active {
        z-index: 2;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1.05);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1.05);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1.05);
        transition: all 200ms ease-in;
        transform: scale(1.05);

        &.shadow-red {
            box-shadow: 0 4px 8px 0 rgba(255, 0, 0, 0.5);
        }

        &.shadow-blue {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 255, 0.5);
        }

        &.shadow-orange {
            box-shadow: 0 4px 8px 0 rgba(255, 165, 0, 0.5);
        }
    }

    label {
        cursor: pointer;
        width: 100%;
    }

    hr {
        margin: 5px 0;
    }
}

table > thead > tr > th.no-sort:after {
    content: none !important;
}

/*Spinner*/

.looping-rhombuses-spinner {
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    width: calc(15px * 4);
    height: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translatey(-100%);

    .rhombus {
        height: 15px;
        width: 15px;
        background-color: #d81b60;
        left: calc(15px * 4);
        position: absolute;
        margin: 0 auto;
        border-radius: 2px;
        transform: translateY(0) rotate(45deg) scale(0);
        animation: looping-rhombuses-spinner-animation 2500ms linear infinite;

        &:nth-child(1) {
            animation-delay: calc(2500ms * 1 / -1.5);
        }

        &:nth-child(2) {
            animation-delay: calc(2500ms * 2 / -1.5);
        }

        &:nth-child(3) {
            animation-delay: calc(2500ms * 3 / -1.5);
        }
    }
}

@keyframes looping-rhombuses-spinner-animation {
    0% {
        transform: translateX(0) rotate(45deg) scale(0);
    }

    50% {
        transform: translateX(-233%) rotate(45deg) scale(1);
    }

    100% {
        transform: translateX(-466%) rotate(45deg) scale(0);
    }
}

span {
    &.__trigger {
        cursor: pointer;
    }

    i {
        transition: all .2s ease-in-out;
    }

    &:hover i {
        &.fa, &.fab {
            transform: scale(1.4);
        }
    }
}

.focus-shake {
    animation: shake 0.5s;
    animation-iteration-count: 1;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.__block-timeline {
    max-height: 490px;
    overflow-y: scroll;
}

.__tab-content-border {
    border: 1px solid #f0f0f0;
}

.__block-new-user .__block-avatar {
    margin-bottom: 20px;
}

.__block-avatar {
    .__input-file input[type='file'] {
        display: none;
    }
}

.__form-help {
    font-size: 11px;
    float: right;

    p {
        margin-bottom: 0;
    }
}

.__form-errors {
    ul {
        padding-left: 0;
    }

    li {
        list-style: none;
    }
}

.__info-tooltip .tooltip-inner {
    max-width: 500px;
    background-color: #fff;
    color: #0a0a0a;
    border: 1px solid #333;
}

/*Alert overlay*/

.__btnActionsTriggerOverlay {
    color: black !important;
    text-transform: uppercase;
    background: #ffffff;
    padding: 1px 10px;
    border: 2px solid white !important;
    border-radius: 6px;
    display: inline-block;
    font-weight: bold;
    background: linear-gradient(to right, transparent 50%, white 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: background .3s ease-out;
    background-color: $bg-highlighted-dark;

    &:hover, &.active {
        color: black !important;
        background-position: right bottom;
    }
}

.__actionsOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    z-index: 10001;
}

.__boxActionsOverlay {
    visibility: hidden;
}

.__boxActionsOverlayContents {
    background: #FFF;
    z-index: 10002;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    -o-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    position: absolute;
    right: 10px;
    min-width: 200px;
    border-radius: 2px 2px 2px 2px;
    text-align: left;
    color: #333;
}

.__boxActionsOverlayHeader {
    border-bottom: 1px solid #e4e5e7;
}

.__boxActionsOverlayBody {
    width: 100%;
}

.__boxActionsOverlayShow {
    visibility: visible;

    .__boxActionsOverlayTransition {
        animation-name: bounceInRight;
        animation-duration: .6s;
        animation-fill-mode: both;
    }
}

@keyframes bounceInRight {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        transform: translate3d(10px, 0, 0);
    }

    90% {
        transform: translate3d(-5px, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

/*Actions list*/

ul.__actionList {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        border-bottom: 1px solid #e4e5e7;
        padding: 6px 12px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:last-child {
            border: none;
        }

        a, span {
            cursor: pointer;
            text-decoration: none;
            color: #333;
            padding: 2px 12px;
        }
    }
}

/*+/- actions*/

.__padding-rl-xs {
    padding-right: 1px;
    padding-left: 1px;
}

.__wrap {
    padding: 1%;
}

.__table_box {
    margin: 2% 0 2% 0;
}

@media (min-width:1920px){
    .product .card{
        width: 50vh; 
        height: 48vh;
        text-align: center; 
        float: left; 
        margin-right: 3em; 
        text-transform: uppercase; 
        border: none; 
        font-weight: bold; 
        -webkit-box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19); 
        box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19);
    }

    .product-title{
        font-size: 20px;
    }
}

@media (min-width:1500px) and (max-width:1919px){
    .product .card{
        width: 50vh;
        height: 60vh;
        text-align: center; 
        float: left; 
        margin-right: 3em; 
        text-transform: uppercase; 
        border: none; 
        font-weight: bold; 
        -webkit-box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19); 
        box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19);
    }

    .product-title{
        font-size: 20px;
    }
}

@media (min-width:1205px) and (max-width:1499px){
    .product .card{
        width: 50vh;
        height: 73vh;
        text-align: center; 
        float: left; 
        margin-right: 3em; 
        text-transform: uppercase; 
        border: none; 
        font-weight: bold; 
        -webkit-box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19); 
        box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19);
    }

    .product-title{
        font-size: 20px;
    }
}

@media (max-width: 1204px){
    .sidebar{
        background-color: white;
    }

    .product .card{
        border: none; 
        font-weight: bold; 
        margin-left: 0.5em;
        -webkit-box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19); 
        box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19);
    }

    .product-title{
        font-size: 17px;
    }
}

@media (min-width: 792px) {
    .product{
        display: flex; align-items: center; justify-content: center; padding-top:2.5em
    }

    .card-picture{
        display: inline-table; width:48%; 
    }

    #switch_user{
        width:250px;
    }
}

@media (max-width: 791px){
    .product .card{
        border: none; 
        font-weight: bold; 
        -webkit-box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19); 
        box-shadow: 5px 5px 4px 1px rgba(0,0,0,0.19);
    }

    .select2-selection__placeholder{
        font-size: 12px;
    }
    .selection{
        visibility: hidden
    }
}

@media (max-width: 768px) {
    .__wrap {
        padding: 0;
    }

    .__table_box {
        width: 100%;
    }
}

.__padding-section {
    padding: 25px 15px;
}

.__btn-new-customer {
    i {
        font-size: 30px;
    }

    a {
        font-size: 16px;
        border-radius: 25px;
        padding: 20px;
        width: 135px;
    }
}

/*Files vouchers*/

.__file-body {
    min-height: 120px;
}

.__sticky-content {
    color: white;
    box-shadow: 8px 8px 12px #aaa;
    z-index: 100;
    background-color: rgb(38, 141, 186);
}

/*contact*/

span a {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;

    &:focus {
        outline: none !important;
    }

    &:hover {
        text-decoration: none;
    }
}

#contact_message {
    outline: none;
    border: none;

    &:focus {
        border-color: transparent !important;
    }

    &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
        color: #adadad;
    }
}

/*---------------------------------------------*/

.contact100-form-btn {
    outline: none !important;
    border: none;
    background: transparent;

    &:hover {
        cursor: pointer;
    }
}

.txt1 {
    font-size: 18px;
    line-height: 1.2;
    color: #1a1a1a;
}

.txt3 {
    font-size: 15px;
    line-height: 1.6;
    color: #00ad5f;
}

.txt4 {
    font-size: 15px;
    line-height: 1.6;
    color: #000000;
}

.bold {
    font-weight: bold;
}

.size1 {
    width: 355px;
    max-width: 100%;
}

.size2 {
    width: calc(100% - 43px);
}

.container-contact100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
}

.wrap-contact100 {
    width: 100%;
    background: #fff;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
}

.contact100-more {
    width: calc(100% - 560px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    padding: 30px 15px 0px 15px;

    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.1);
    }
}

.contact100-form {
    width: 560px;
    min-height: 100vh;
    display: block;
    padding: 110px 55px 55px 55px;
}

.contact100-form-title {
    width: 100%;
    display: block;
    font-size: 40px;
    font-weight: 200;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    padding-bottom: 10px;
}

.wrap-input100 {
    width: 100%;
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 5%;
}

.label-input100 {
    font-size: 11px;
    color: #666666;
    line-height: 1.2;
    text-transform: uppercase;
    padding: 15px 0 2px 24px;
}

.input100 {
    display: block;
    width: 100%;
    background: transparent;
    font-size: 18px;
    color: #404b46;
    line-height: 1.2;
    padding: 0 26px;
}

input.input100 {
    height: 48px;
}

textarea.input100 {
    min-height: 130px;
    padding-top: 14px;
    padding-bottom: 15px;
}

.focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #6675df;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    -moz-transform: scaleX(1.1) scaleY(1.3);
    -ms-transform: scaleX(1.1) scaleY(1.3);
    -o-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus + .focus-input100 {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.container-contact100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10px;
}

.contact100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: #6675df;
    font-size: 12px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    &:hover {
        background: #404b46;
    }
}

@media (max-width: 992px) {
    .contact100-form {
        width: 50%;
        padding: 110px 30px 55px 30px;
    }

    .contact100-more {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .contact100-form {
        width: 100%;
    }

    .contact100-more {
        display: none;
    }
}

@media (max-width: 576px) {
    .contact100-form {
        padding: 110px 15px 55px 15px;
    }
}

.validate-input {
    position: relative;
}

.wrap-input100 {
    width: 100% !important;
    width: calc(100% + 2px) !important;
}

.p-b-47 {
    padding-bottom: 47px;
}

.p-r-25 {
    padding-right: 25px;
}

.dis-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: $white;
}

/*end contact*/

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    padding: 4px;
    margin: 0 0 10px 65px;
    list-style: none;
    background-color: #ffffff;
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    white-space: nowrap;
    text-decoration: none;
}

.ui-state-hover, .ui-state-active {
    color: #ffffff;
    text-decoration: none;
    background-color: #0088cc;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    background-image: none;
}

.center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


/* autoconnect spring assur */

#autoConnectForm {
    padding: 12px 5px 12px 15px;
    display: block;
}

.__auto-connect-li :hover {
    cursor: pointer;
    background-color: #1e282c;
}

.__w-100 {
    width: 100%;
}

.sm-padding > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
}
