.nav-tabs {
  .nav-link {
    border: 0;
    color: $text-muted;
    transition: color 300ms;
    position: relative;
    font-size: 1.1rem;

    &:before {
      content: "";
      height: 2px;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: $white;
      transform: scale(0);
      transition: transform 300ms;
    }

    &.active {
      &:before {
        transform: scale(1);
      }
    }
  }
}

.tab-content {
  padding: 1.5rem 0;
}